import { Feedback, Footer, Navigation } from '@libs/components';
import { useEffect } from 'react';
import { addMetaTag, removeMetaTag } from '@libs/components/pageBlocks/TariffBlock/utils/utils';

import { MainLogo } from './components/MainLogo';
import { PosAlgopack } from './components/PosAlgopack';
import { ApiData } from './components/ApiData';
import { HowPlug } from './components/HowPlug';
import { Hackathon } from './components/Hackathon';
import { Examples } from './components/Examples';
import { Links } from './components/Links';
import { DataConstitution } from './components/DataConstitution';
import { PageDisclaimer } from './components/PageDisclaimer';
import { OpenFutures } from './components/OpenFutures';

export const AlgopackPage = () => {
    useEffect(() => {
        addMetaTag('title', 'AGLOPACK: торговля через API | Алгоритмическая торговля с помощью Datashop');
        addMetaTag(
            'description',
            'AgloPack - ваш ключ к алгоритмической торговле через API. Сервис предоставляет все необходимые инструменты для онлайн трейдинга, включая доступ к данным и аналитическим сигналам с Московской биржи. Получите преимущество с нашими алгоритмами, библиотеками и примерами кода для создания и тестирования торговых стратегий.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <MainLogo />
                    <PosAlgopack />
                    <DataConstitution />
                    <ApiData />
                    {/*<HowPlug />*/}
                    <Links />
                    <OpenFutures />
                    <Hackathon />
                    <Examples />
                    {/* <ExampleGraph /> */}
                    <PageDisclaimer />
                    <Feedback isAbsolute />
                </div>
            </div>
            <Footer />
        </>
    );
};
