import { Footer, Navigation } from '@libs/components';
import { addMetaTag, removeMetaTag } from '@libs/components/pageBlocks/TariffBlock/utils/utils';
import { MainContent } from '@modules/ProPackLanding/MainContent';
import { useEffect } from 'react';

export const ProPackLanding = () => {
    useEffect(() => {
        addMetaTag('title', 'Pro Pack - сервис глубокой биржевой аналитики и работы с данными');
        addMetaTag(
            'description',
            'Всесторонний анализ торговли на Московской бирже и профессиональная аналитика биржевых рынков от сервиса Pro Pack. Платформа предоставляет актуальные данные и инсайты, помогая трейдерам и инвесторам принимать решения на основе анализа динамики рынка.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <MainContent />
                </div>
            </div>
            <Footer />
        </>
    );
};
