import { DatashopTariff, TariffItemType, TariffValue } from '@libs/types';

export const createTariffPlanListByPeriod = (
    datashopTariff: DatashopTariff,
    selectedPeriodTariff: TariffValue,
    tariffsPlanList: TariffValue[],
) => {
    let res: TariffItemType[] = [];
    res = datashopTariff.billingTables[0].tariffPlans
        .filter((item) => item.tariffParams.find((tariffParam) => tariffParam.valueGuid === selectedPeriodTariff.guid))
        .sort((a, b) => {
            const aPos = tariffsPlanList.find((tariff) =>
                a.tariffParams.find((item) => item.valueGuid === tariff.guid),
            )?.valueOrder;
            const bPos = tariffsPlanList.find((tariff) =>
                b.tariffParams.find((item) => item.valueGuid === tariff.guid),
            )?.valueOrder;
            return aPos && bPos ? bPos - aPos : 0;
        })
        .reduce((acc, curr) => {
            const title = tariffsPlanList.find((tariff) =>
                curr.tariffParams.find((item) => item.valueGuid === tariff.guid),
            )?.value;
            acc.push({ ...curr, title: title || '' });
            return acc;
        }, res);
    return res;
};

export const addMetaTag = (title: string, content: string) => {
    const metaIsExisted = document.querySelector(`meta[name="${title}"]`);

    if (metaIsExisted) {
        return;
    }

    const metaTag = document.createElement('meta');

    metaTag.name = title;
    metaTag.content = content;

    document.head.appendChild(metaTag);
};

export const removeMetaTag = (title: string) => {
    const metaIsExisted = document.querySelector(`meta[name="${title}"]`);

    if (metaIsExisted) {
        document.head.removeChild(metaIsExisted);
    }
};
